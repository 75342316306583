import React from "react";
import { ThemeProvider } from "styled-components";
import { fortellisTheme } from "@cdk-rds/core";
import { Content } from "@cdk-rds/typography";
import { CircularProgress, Fluid, FluidItem } from "@cdk-uip/react";
import {
  CardBody,
  CardContent,
  CardWrapper,
  CardSubtitle,
  CardTitle
} from "@cdk-rds/card";
import {SearchInput, PrimaryButton, Divider} from 'cdk-radial';
import PropTypes from "prop-types";
import config from "../config/app.conf.json";
import { joinOrg } from "./JoinOrganizationActions";
import {  withAuth } from "@cdk-prod/fortellis-auth-context";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { sendAmplitudeData } from "../utils/amplitude";
import AuthFooter from "../Footer/AuthFooter";
import './NoResults.scss';
import AuthHeader from "../Header/AuthHeader";

const defaultRedirectURL = config.nav.user.menu[0].url || window.location.host;
export class JoinOrgComp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      requestActive: [],
      isLoading: true,
      value: '',
      filteredOrgs: [],
    };
    this.handleRequestAccess = this.handleRequestAccess.bind(this);
  }

  handleChange = searchValue => {
    this.setState({
      value: searchValue
    });
    const result = this.state.data.filter(e =>
      e.name.toUpperCase().includes(searchValue.toUpperCase())
    );
    this.setState({
      filteredOrgs: result
    });
  };

  logAmplitudeAccessRequest = (email, orgId) => {
    const ampliData = {
      UserEMail: email,
      OrganizationId: orgId
    };
    sendAmplitudeData("Request Access", ampliData);
  };

  handleRequestAccess(orgId) {
    const objectIndex = this.state.data.findIndex(
      element => element.id === orgId
    );
    const newItems = [...this.state.data];
    newItems[objectIndex].status = "Approval pending";
    this.setState({ data: newItems });
    let configUrl = config.api.updateInvitation;
    const { auth } = this.props;

    if (auth) {
      const accessToken = auth.accessToken;
      const email = auth.userData.email;
      let requestJoinOrgPayload = {
        initiatedBy: "user",
        orgId: orgId,
        emailId: email
      };
      fetch(configUrl, {
        method: "post",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(requestJoinOrgPayload)
      }).then(res => res.json());
    }
    this.logAmplitudeAccessRequest(auth.userData.email, orgId);
  }

  changeBackgroundOnHover(e) {
    e.target.parentElement.style.backgroundColor = "rgb(21, 27, 37,0.05)";
  }
  restoreBackground(e) {
    e.target.parentElement.style.backgroundColor = null;
    e.target.parentElement.style.opacity = 1;
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.data && this.props.data !== nextProps.data) {
      this.setState({
        data: nextProps.data,
        filteredOrgs: nextProps.data,
        isLoading: false
      });
    }
  }

  componentDidMount() {
    const { auth } = this.props;
    if (auth) {
      const accessToken = auth.accessToken;
      let emailId = auth.userData.email;
      const splitEmail = emailId.split("@");
      const domain = splitEmail[1];
      this.props.joinOrg(accessToken, domain);
    }
  }

  render() {
    return (
      <>
      <div className="listOfOrganization___header">
        <AuthHeader />
      </div>
      <ThemeProvider theme={fortellisTheme}>
        {this.state.isLoading ? (
          <div className="listOfOrganization__spinner">
            <Fluid
              className="circularprogress__outer-wrapper"
              halign="center"
              valign="middle"
            >
              <FluidItem className="circularprogress__direct-wrapper">
                <CircularProgress className="circularprogress__component" />
              </FluidItem>
            </Fluid>
          </div>
        ) : (
          <>
            <div className="parent-div" style={{flex: 1, padding:"0px"}}>
              <div className="listOfOrganization__Page-wrapper">
                <CardWrapper className="listOfOrganization__Page-wrapper-content">
                  <CardBody>
                    <CardTitle className="listOfOrganization__text-head" style={{width:"600px"}}>
                      Join an Organization
                    </CardTitle>
                    <CardSubtitle className="listOfOrganization__text">
                      You may request access to the following organizations
                      where an org admin will need to approve your request.
                      If you cannot find the organization, contact your org
                      admin for an invitation. You can also request an
                      organization to be created.
                    </CardSubtitle>
                  </CardBody>
                  <PrimaryButton
                    style={{
                      marginTop: "16px",
                      marginBottom: "30px",
                      textDecoration: "none"
                    }}
                    href={`${defaultRedirectURL}/registration`}
                    text="Request a New Organization"
                  />
                  <Divider />
                  <SearchInput
                    clearButtonLabel="clearButtonLabel"
                    id="search-input-expanded"
                    isAlwaysExpanded
                    name="search-input-expanded"
                    onChange={this.handleChange}
                    placeholder="Search Organizations"
                    value={this.state.value}
                    onFocus={() => {}}
                    onBlur={() => {}}
                    style={{ marginBottom: "16px", marginTop: "30px" }}
                  />
                  {!this.state.filteredOrgs.length ? (
                    <div className="no_results_container-nl">
                      <label className="no-results-found-for_label-nl">
                        {`No results found for `}
                        <span className="no-results-found-for_searchText-nl">
                          {this.state.value}
                        </span>
                      </label>
                      <label className="check-spelling-and_label-nl">
                        Check spelling and try your search again
                      </label>
                    </div>
                  ) : null}

                  <CardContent className="listOfOrganization__form-wrapper">
                    <div className="listOfOrganization__card--wrapper">
                      <div>
                        {this.state?.filteredOrgs.map(item =>
                          (
                            <div
                              className="listOfOrganization__card--wrapper"
                              data-testid="listOfOrganization-item-wrapper"
                            >
                              <Content
                                tag="div"
                                type="body-2"
                                className="listOfOrganization__text--body"
                                title={item.name}
                              >
                                {item.name}{" "}
                              </Content>

                              <Content
                                className="listOfOrganization__text--content"
                                tag="div"
                                type="body-1"
                              >
                                {item.users.length}{" "}
                                {item.users.length === 1 ? "Member" : "Members"}
                              </Content>

                              <PrimaryButton
                                data-testid="request-access"
                                className="listOfOrganization__button--secondary"
                                isDisabled={item.status === "Approval pending"}
                                text={
                                  item.status === "approved"
                                    ? "Request Access"
                                    : "Approval pending"
                                }
                                type=""
                                onClick={() => {
                                  this.handleRequestAccess(item.id);
                                }}
                                onMouseOver={this.changeBackgroundOnHover}
                                onMouseOut={this.restoreBackground}
                                onMouseUp={this.restoreBackground}
                              ></PrimaryButton>
                            </div>
                          )
                        )}
                      </div>

                      <div className="listOfOrganization__button-wrapper--primary">
                      </div>
                    </div>
                  </CardContent>
                </CardWrapper>
              </div>
            </div>
            <div className="listOfOrganization__footer">
              <AuthFooter />
            </div>
          </>
        )}
      </ThemeProvider>
      </>
    );
  }
}

JoinOrgComp.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  sessionToken: PropTypes.string.isRequired,
  listOfOrg: PropTypes.func.isRequired,
  status: PropTypes.string,
  error: PropTypes.object,
  goToSignup: PropTypes.func,
  organizations: []
};

const mapStateToProps = state => {
  return { data: state.joinOrg.organizations };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      joinOrg
    },
    dispatch
  );
};


const JoinOrganization = withRouter(connect(mapStateToProps, mapDispatchToProps)(JoinOrgComp))


class JoinOrganizationContainer extends React.Component {
  render(){
    return this.props.auth.userData.sub
    ? <JoinOrganization {...this.props}/>
    :<></>
  }

}
export default withAuth(JoinOrganizationContainer);
